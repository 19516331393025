<template>
    <div class="founders-faq">
        <nav-bar />
        <div class="container-md pt-5">
            <figure>
                <img
                    src="@assets/img/founders-landing/faq/header.jpg"
                    srcset="@assets/img/founders-landing/faq/header.jpg 1x, @assets/img/founders-landing/faq/header@2x.jpg 2x"
                    alt="Faq"
                >
                <span>FAQ</span>
            </figure>
            <div class="faq-container">
                <vue-tabs class="faq-categories">
                    <v-tab v-for="(category, index) in faq" :key="index" :title="category.title">
                        <BulmaAccordion
                            v-if="isMobileScreen"
                            class="bulma-accordion"
                            :icon="'custom'"
                        >
                            <BulmaAccordionItem v-for="(question, questionIndex) in category.questions" :key="questionIndex">
                                <h4 slot="title">
                                    {{ question.title }}
                                </h4>
                                <div slot="content" v-html="question.content" />
                            </BulmaAccordionItem>
                        </BulmaAccordion>
                        <vue-tabs v-else class="vertical-vue-tab" direction="vertical">
                            <v-tab v-for="(question, questionIndex) in category.questions" :key="questionIndex" :title="question.title">
                                <h1 class="question-title">
                                    {{ question.title }}
                                </h1>
                                <div v-html="question.content" />
                            </v-tab>
                        </vue-tabs>
                    </v-tab>
                </vue-tabs>
            </div>
        </div>
        <landing-footer />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { VueTabs, VTab } from "vue-nav-tabs";
import { BulmaAccordion, BulmaAccordionItem } from "vue-bulma-accordion";
import "vue-nav-tabs/themes/vue-tabs.css";

export default {
    name: "FoundersFaq",
    components: {
        VueTabs,
        VTab,
        BulmaAccordion,
        BulmaAccordionItem,
        NavBar: () => import(/* webpackChunkName: "nav-bar" */ "@c/organisms/nav-bar"),
        LandingFooter: () => import(/* webpackChunkName: "landing-footer" */ "@v/landing/footer")
    },
    data() {
        return {
            faq: [
                {
                    title: "Founder Program",
                    questions: [
                        {
                            title: "What is the Founding Creators Program and why is it unique?",
                            content: `<p>The Founding Creator Program is a limited entry, specially crafted program for a small group of initial creators that join the Memo’d platform. Our goal is to create a unique and guided experience to help the first group of creators navigate the new platform, while also participating in a more financially rewarding arrangement.</p>
                            <p>Founding Creator Program participants are highly curated, individually identified and sought after, given our belief in their ability to bring together strong audience readership and well-crafted content. Our goal here is two-fold: to attract high-quality content for the platform, and keep initial creators excited and motivated to succeed.</p>
                            <p>Program participants will get assistance from our internal team on editing, writing and engagement best practices, and other related services. We will provide pathways for creators to get familiar with the platform and help jumpstart their first few Memos. See more information below on support and tools provided to creators.</p>`
                        },
                        {
                            title: "What are the benefits and responsibilities of a program participant?",
                            content: `<p>Founder Program participants enjoy a number of benefits, including assistance from our internal team on editing and writing content, engaging best practices, and the lowest tier platform fee on monthly earnings. These benefits serve to help program participants thrive on the platform.</p>
                            <p>As an active participant in the Founder Program, creators are expected to actively produce new Memos on a recurring and periodic basis. We encourage participants to follow a structured cadence of at least 1 new Memo per week, but certainly welcome those who wish to push out more content through the platform for increased monetization potential.</p>
                            <p>We also strongly encourage program participants to actively engage with the platform’s readers and audience. Such exchanges not only help grow a creator’s own readership, but also contribute to the broader community for Memo’d.</p>`
                        },
                        {
                            title: "What additional types of monetization options are offered to participants?",
                            content: `<p>On the Memo’d platform, monetization for creators come in two forms: one-time tips and recurring patronage.</p>
                            <p><strong>One-Time Tips</strong> are tipping mechanisms akin to YouTube Super Chat, and are available as an option for all verified creators. Creators will see a tipping button after each Memo they publish. Memos that can earn tips will be labeled and will also include information such as # of readers who have tipped. Readers who tip will also be able to send a message (up to 300 characters) or virtual sticker (separately purchased) with their tip. There will be preset tipping amounts that readers can choose from, ranging from $0.99 to $99.99. Creators can respond to all tips with either a message or “like” acknowledgment.</p>
                            <p><strong>Recurring Patronage</strong> is a membership mechanism akin to a “monthly donation” subscription, and is available to creators who have achieved Super Creator status. Qualifying creators can create different tiers of memberships, similar to YouTube Channel Membership or Patreon, with varying levels of perks assigned to each tier. For example, a creator could create member-specific perks such as exclusive Memos or early-access Memos, or they could run events and live streams. It’s completely up to creators how they leverage these tools to engage with their followers and monetize their content.</p>`
                        },
                        {
                            title: "What is the platform rate structure for the Founding Creators Program?",
                            content: `<p>Founder Program participants enjoy a flat 5% platform fee, which will never increase so long as they remain active as a creator, producing content regularly and engaging with the community.</p>`
                        },
                        {
                            title: "Do members of the Founding Creators Program have to pay a fee?",
                            content: `<p>Founder Program participation is free, regardless of whether you join by invitation or application. However, you will still be subject to a 5% platform fee on earnings made on the platform, for both one-off donations (“One-Time Tips”) and monthly donations (“Recurring Patronage”).</p>`
                        },
                        {
                            title: "What support or tools are available to program participants?",
                            content: `<span>Founder Program participants will receive the following support from the Memo’d team:</span>
                            <ul>
                                <li>Editorial assistance for newly created Memos, including help and guidance with style, structure and language.</li>
                                <li>Best practices on reader engagement to ensure the Memos appeals to a broad audience.</li>
                                <li>Periodic review of readership and audience analytics to provide quantitative feedback.</li>
                                <li>In select instances, Memo cover art design support to help pique interest with readers.</li>
                                <li>In select instances, ghostwriting support for creators who have a lot of know-how to communicate but need help with the writing process.</li>
                            </ul>`
                        },
                        {
                            title: "Can an individual be referred to, or apply to, the program?",
                            content: `<p>Founder Program participants may refer another individual to the program, but all such referrals will go through the regular evaluation. Referred individuals are not automatically guaranteed a spot in the program.</p>
                            <p>Non-invited individuals may also apply to the program directly. Although we do not publicize the application process, we will review all requests to join. </p>
                            <span>Applications should include the following:</span>
                            <ul>
                                <li>Full name and contact information</li>
                                <li>Brief message saying why you want to join</li>
                                <li>Content area and domain expertise</li>
                                <li>Links to published content (if any)</li>
                                <li>Sample of published content (if any)</li>
                                <li>Links to Twitter, Instagram, or other relevant social accounts</li>
                                <li>Links to personal websites, blogs, or other content accounts</li>
                            </ul>`
                        },
                        {
                            title: "Does the Founding Creators Program have an expiration date or end date?",
                            content: `<p>The Founder Program does not have an expiration date for its active participants.</p>
                            <p>However, participants who are not active in either content creation or audience engagement may be removed from the program at our discretion.</p>
                            <p>Invitations to the Founder Program will cease once the program has reached its target milestones.</p>`
                        }
                    ]
                },
                {
                    title: "Creator Participation",
                    questions: [
                        {
                            title: "How do creators get verified? What are the benefits of verification?",
                            content: `<p>Anyone can publish Memos and become a creator, but in order to receive donations / tips, a creator must submit specific payment acceptance details (full name, tax identifier, Stripe account, etc). Once such details are submitted and the Memo’d platform approves, the creator becomes verified and is eligible to receive one-time donations.</p>
                            <p>In order for a creator to have membership subscriptions enabled for recurring patronage, that creator must reach a certain threshold of total ratings or total reads. Currently this threshold is set at either 1,000 ratings or 10,000 reads. This helps ensure that those who desire to monetize their content are serious about providing contributions at a certain level of quality and frequency. The threshold is removed for Founders Program participants.</p>
                            <p>Verified creators will have the opportunity to be featured and promoted by the platform. Additionally, “donate” button will appear on all of their Memos, plus access to a metrics dashboard. The dashboard will show their earnings progress across different Memos and timeframes.</p>
                            <p>Please note that a verified creator can have their verification status (and monetization eligibility) removed in extreme circumstances. For example, if their Memos receive a sufficient number of complaints about inappropriate or abusive content or spam.</p>`
                        },
                        {
                            title: "Are there ways for specific creators to get featured or promoted by the platform?",
                            content: `<p>It is possible for specific creators to be featured on the Memo’d platform. The internal team regularly reviews popular or up-and-coming creators, and reaches out to work with them to feature new Memos in a variety of “Boards”, or collections, as well as key featurettes on the platform feed.</p>
                            <p class="mb-0">
                                Example of Boards (collections of Memos):
                            </p>
                            <img loading="lazy" class="img-fluid" src="${require("@assets/img/founders-landing/faq/collection-example.jpg")}" alt="Collection example">`
                        },
                        {
                            title: "Can a single creator have multiple accounts or profiles on the platform?",
                            content: `<p>Yes, a single creator can have multiple accounts, for example to keep each one focused on distinctly different themes and target audiences.</p>
                            <p>However, a creator’s success and ability to grow his or her audience is often directly tied to the growth of a specific account. Monetization potential is also highly dependent on the size and engagement of their follower base.</p>
                            <p>Maintaining multiple accounts may become difficult unless a creator is able to adequately devote the needed time to each account. We encourage all creators to weigh the pros and cons of maintaining multiple accounts.</p>`
                        },
                        {
                            title: "Can creators cross-promote each other’s content through the platform?",
                            content: `<p>Yes, in fact we encourage creators to cross-promote one another and maximize both their audience reach and content value.</p>
                            <p>Memo’d offers a quoting feature within its publishing toolkit, whereby a creator can QUOTE or link to sections of another creator’s Memo, or link to a whole Memo by inputting its URL. Creators can also link to an external URL.</p>
                            <p>A creator can also MENTION another creator in his or her Memos, which in effect creates a link to the latter’s profile page. Creators can also talk about each other’s Memos in their respective community chats.</p>
                            <p>Lastly, the Memo’d algorithm also shows Recommended or Similar Memos at the end of each Memo to help users find more relevant content to stay engaged.</p>
                            <span>Example of how Recommended content appears at the base of Memos:</span>
                            <img loading="lazy" class="img-fluid" src="${require("@assets/img/founders-landing/faq/recommended-example.jpg")}" alt="Recommended example">
                            <p class="mt-4 mb-0">
                                Example of the QUOTE feature:
                            </p>
                            <img loading="lazy" class="img-fluid" src="${require("@assets/img/founders-landing/faq/quote-example.jpg")}" alt="Quote example">
                            <p class="mt-4 mb-0">
                                Example of the MENTION feature:
                            </p>
                            <img loading="lazy" class="img-fluid" src="${require("@assets/img/founders-landing/faq/mention-example.jpg")}" alt="Mention example">`
                        },
                        {
                            title: "Are creator accounts and normal reader accounts distinct? Or can a user be both?",
                            content: `<p>Every Memo’d account serves the dual purpose of being both a creator account and a user account. (This is similar to YouTube / Twitter / Instagram accounts)</p>
                            <p>Verified creators participating in the Memo’d monetization program will have a dashboard to see additional monetization features such as earning history, advanced content analytics, community chat moderator role, etc.</p>
                            <p class="mb-0">
                                Example user profile fields:
                            </p>
                            <img loading="lazy" class="img-fluid" src="${require("@assets/img/founders-landing/faq/profile-example.jpg")}" alt="Profile example">`
                        }
                    ]
                },
                {
                    title: "Content Creation Policies",
                    questions: [
                        {
                            title: "Are there specific format or length restrictions for content creation?",
                            content: `<p>All Memos follow a point-by-point structure. A standard Memo is 10 points, while multipart Memos are generally standardized at 20 points or 30 points.</p>
                            <p>It is possible for Memos to be less than 10 points, or less than 20 points in a multipart Memo, but the majority of Memos follow the convention of multiples of 10 points.</p>
                            <p>Multipart Memos are introduced as new “parts” once a particular Memo goes over the standard 10 points, giving the creator options on how to extend. Creators can add subheadings for each part.</p>
                            <p>Memo points can be formatted as either bullets or numbers.</p>
                            <p>Memo points also have a character limit of 300. With spaces included, this amounts to roughly 50 words. During Memo creation, an indicator will show proximity to the limit.</p>
                            <p>Each Memo can have a single image or carousel of images at the top. A creator can also attach images onto individual Memo points.</p>`
                        },
                        {
                            title: "How do you keep the standard of Memos high?",
                            content: `<p>Founder Program participants invited to the platform are hand-picked for expertise in their field and/or writing ability. Their Memos are selectively reviewed by an internal editorial team to help ensure quality and impact.</p>
                            <p>The internal team will highlight or display in the feed the most outstanding Memos, notifying Memo’d users of new and existing Memos that may inspire them.</p>
                            <p>Once Memos are published, Memo’d employs an algorithm that’s driven by user reads, likes, and shares. This ensures that the most impactful Memos are exposed to a high number of users. The combination of our algorithm and editorial control ensures that the best Memos rise to the top.</p>`
                        },
                        {
                            title: "How is Memo’d different from Twitter and Twitter threads?",
                            content: `<p>Memo’d is focused on long shelf-life content that carries evergreen value, while Twitter content follows a 24/7 news cycle.</p>
                            <p>Whereas Memo’d is like a giant library of insights and ideas, Twitter is more like a noisy arena – great for spontaneous chatter, less useful for knowledge sharing.</p>
                            <p>Memo’d content is designed to be easily shared (via platforms like Whatsapp) to friends, family and colleagues. Tweets, on the other hand, are mostly viewed and retweeted within the Twitter platform.</p>
                            <p>Memo’d makes connected chains of ideas easy to create, share, and consume. Twitter isn’t designed for such a format, making a long strand of tweets difficult to read and absorb. </p>
                            <p>Memos can have multiple images to illustrate the points being made, whereas Twitter threads are not formed that way.</p>
                            <p>Finally, Memo’d lets you permanently store your favorite points, quotes, and lines from the platform in a way that’s easy to find and review. Twitter merely has bookmarking of tweets. </p>`
                        },
                        {
                            title: "Can a piece of content be modified or removed after it’s posted?",
                            content: `<p>Yes. Memo Edits will have an ‘Edited’ indication and a timestamp to indicate the date last edited.</p>`
                        },
                        {
                            title: "Can content posted to the platform also be posted elsewhere?",
                            content: `<p>Yes, though we recommend that you link back to the source of your content: your original Memo on Memo’d. This way, people can discover your other Memos and follow you on the Memo’d platform.</p>`
                        },
                        {
                            title: "Will all content be visible to all readers, or is there private content?",
                            content: `<p>Most published Memos will be available to all readers.</p>
                            <p>However, Super Creators who achieve a certain audience threshold and have crafted their own Recurring Patronage offering (a.k.a. Subscriptions) will be able to create subscriber-only content. This could include, for instance, exclusive Memos or early access Memos.</p>`
                        },
                        {
                            title: "Is content posted to the platform subject to freedom of speech protection?",
                            content: `<p>We believe in freedom of speech for our creators, so in most cases your content and freedom to express is protected. </p>
                            <p>There are some notable exceptions where content is considered infringement, deliberate spam, or abusive, and we reserve the right to remove or block this content.</p>
                            <span>The exceptions are:</span>
                            <ul>
                                <li>Pornographic content, either in words or images</li>
                                <li>Content that deliberately incites violence or hate, or that depicts strong violence</li>
                                <li>Content, including comments, that aim to harass or victimize creators or other commenters</li>
                                <li>Marketing or promotional content, including links</li>
                                <li>Spam or automated content</li>
                                <li>Content that is owned or copyrighted by a person, company or institution. Creators need their permission to publish</li>
                                <li>Private information such as emails, messages, photos which the owner has not given you permission to publish</li>
                                <li>Content that is classified by a government</li>
                            </ul>`
                        },
                        {
                            title: "What sort of content should I publish on the platform?",
                            content: `<p>There are no restricted categories or topics as long as they do not breach the freedom of speech exceptions above.</p>
                            <p>That being said, we encourage creators to consider the genre of content they create, and whether it will 1) add value to the platform, and 2) attract readership from Memo’d users.</p>
                            <p>Memo’d is dedicated to helping its users grow in knowledge and advance in work and life. Categories may include, but are not limited to: Business, Finance, Entrepreneurship, Work Skills, Psychology, Self-Help, Health, Politics, Economics, Sociology, Philosophy and Science.</p>
                            <p>Memo’d is a platform for non-fiction content, so you are encouraged to use other platforms for fictional content. </p>`
                        },
                        {
                            title: "Are there restrictions on source material that content creators may use?",
                            content: `<p>You can draw on any content (such as a book, podcast, article, or talk) for inspiration, but make sure you add the source in the correct field when creating a Memo. Keep any verbatim quotes to a bare minimum. When you do quote, always add the name of the source in the Memo point where the quote appears.</p>
                            <p>Any copyright infringement stemming from a Memo you create will not only mean that the Memo gets removed, but all subsequent legal ramifications of infringement will be your responsibility.</p>`
                        },
                        {
                            title: "Can multiple creators develop content from the same source material?",
                            content: `<p>Yes. It is likely that your take on a source will be different from other people anyway. Multiple viewpoints on a topic or subject matter are always welcomed.</p>`
                        },
                        {
                            title: "Do content submissions go through a review or editorial process?",
                            content: `<p>Yes. It is likely that your take on a source will be different from other people anyway. Multiple viewpoints on a topic or subject matter are always welcomed.</p>
                            <span>We reserve the right to review all content published on Memo’d. Whenever you publish content, you implicitly give us the right to:</span>
                            <ul>
                                <li>Review that content for appropriately, and reject or take down inappropriate content</li>
                                <li>Display or use that content to increase the likelihood of it being seen and read</li>
                            </ul>
                            <p>This also means we may suggest changes or edits to your content where we think it will improve its chances of being popular or useful to users.</p>`
                        }
                    ]
                },
                {
                    title: "Community Engagement",
                    questions: [
                        {
                            title: "What impact do reader ratings, likes, or views have on a piece of content?",
                            content: `<p>Memo’d has a variety of recommendation and discovery mechanisms to allow great content to stand out. All of these mechanisms take into account ratings, likes, or views to some degree, so it is important to be mindful of such quality-driven metrics.</p>
                            <p>For example, Memos that receive high levels of ranking, likes, and views, will have a higher chance to become trending or featured, and will subsequently receive more traffic in the feed.</p>
                            <p>Creators can decide how they distribute their Memos off-platform, since Memo’d ratings and view metrics do not influence those other channels.</p>`
                        },
                        {
                            title: "Can a creator engage with readers and the broader platform community?",
                            content: `<p>Yes, there are a variety of ways creators can engage readers, and we encourage such interaction as they build community and add value to all parties.</p>
                            <p>Creators can join community chats, respond to comments on their own Memos or those of others, and even direct message other users and creators in most circumstances.</p>`
                        },
                        {
                            title: "Does the platform help creators track and analyze reader engagement?",
                            content: `<p>Yes, Memo’d offers a dashboard for creators to visualize their key content metrics and reader analytics.</p>
                            <p class="mb-0">
                                Example of the Analytics screen:
                            </p>
                            <img loading="lazy" class="img-fluid" src="${require("@assets/img/founders-landing/faq/analytics-example.jpg")}" alt="Analytics example">`
                        },
                        {
                            title: "Are there direct messaging or private/small group messaging features?",
                            content: `<p>Yes, Memo’d offers direct messaging capabilities amongst creators and readers.</p>
                            <p class="mb-0">
                                Example of the Direct Messaging screen:
                            </p>
                            <img loading="lazy" class="img-fluid" src="${require("@assets/img/founders-landing/faq/dm-example.jpg")}" alt="DM example">`
                        },
                        {
                            title: "Is there a way to flag or up/down vote readers’ comments?",
                            content: `<p>Yes, users can upvote or downvote comments based on helpfulness, appropriateness, and general accuracy. We believe the community should be involved in shaping conversations and helping to guide discourse.</p>
                            <p>If a specific comment is particularly egregious, users can flag it for administrative review. If the review deems the comment to be a violation of standards, it will be promptly removed. If the same reader repeatedly posts comments that get flagged, we may take administrative action on his or her account.</p>`
                        },
                        {
                            title: "Can I share my content via messaging apps and to social media platforms?",
                            content: `<p>Yes, there are numerous built-in buttons and actions that allow for easy sharing of content to a variety of social media platforms, messaging apps, and other outlets.</p>
                            <p>You can see examples of such sharing functions below. The first is profile sharing, the second is Memo sharing.</p>
                            <div class="row flex-wrap">
                                <div class="col-12 col-lg-6">
                                    <img
                                        loading="lazy"
                                        class="img-fluid mb-4"
                                        src="${require("@assets/img/founders-landing/faq/share-example.jpg")}"
                                        alt="Share example"
                                    >
                                </div>
                                <div class="col-12 col-lg-6">
                                    <img
                                        loading="lazy"
                                        class="img-fluid"
                                        src="${require("@assets/img/founders-landing/faq/share-example-2.jpg")}"
                                        alt="Share example"
                                    >
                                </div>
                                <div class="col-12 col-lg-6">
                                    <img
                                        loading="lazy"
                                        class="img-fluid"
                                        src="${require("@assets/img/founders-landing/faq/share-example-3.jpg")}"
                                        alt="Share example"
                                    >
                                </div>
                                <div class="col-12 col-lg-6">
                                    <img
                                        loading="lazy"
                                        class="img-fluid"
                                        src="${require("@assets/img/founders-landing/faq/share-example-4.jpg")}"
                                        alt="Share example"
                                    >
                                </div>
                            </div>`
                        }
                    ]
                },
                {
                    title: "Donations and Tipping",
                    questions: [
                        {
                            title: "What fees are deducted from user donation or pledge amounts?",
                            content: `<span>The Memo’d standard fee (after iOS platform fee/bank charge), applied to a (non-founder) creator’s total monthly platform earnings, is:</span>
                            <ul>
                                <li>20% of up to $1,000 earnings</li>
                                <li>15% of $1,000 to $10,000</li>
                                <li>10% of $10,000 to $25,000</li>
                                <li>5% of $25,000 or over</li>
                            </ul>`
                        },
                        {
                            title: "How are donations paid out to the creators? Are there minimums?",
                            content: `<p>All platform earnings are tallied on a monthly basis. The minimum threshold for a payout is $100. This means that if your current monthly total is less than $100, that balance will roll over to the following month’s tally, until such time that the running balance is over $100, at which point you will be paid out at the end of that month.</p>
                            <p>All payments will be issued via Stripe, which will deposit into your designated bank account.</p>`
                        },
                        {
                            title: "Are all types of donations subject to the same payout minimums?",
                            content: `<p>All donation types, irrespective of “One-Time Tips” or “Recurring Patronage”, are tallied toward the same overall monthly platform earnings calculation. Therefore they are all subject to a single payout threshold.</p>`
                        },
                        {
                            title: "How frequently are donations/accumulated balances paid out?",
                            content: `<p>Platform earnings are subject to be paid out on a monthly basis, assuming the balance has met the payout threshold of $100 for that month.</p>`
                        },
                        {
                            title: "What currencies or denominations are the donations and payouts?",
                            content: `<p>At this time, all platform earnings payouts are made in US Dollars. Additional currencies or denominations may be included at a later date.</p>`
                        },
                        {
                            title: "Does the balance of accumulated donations ever expire prior to payout?",
                            content: `<p>Accumulated platform earnings do not expire unless the account is shut down due to abuse, fraud, or other egregious activities.</p>`
                        },
                        {
                            title: "Are donations tied to specific pieces of content or to the creator?",
                            content: `<p>One-Time Tips / Donations are generally tied to a specific Memo, though there is no limit in how many times a reader can tip or donate for the same Memo.</p>
                            <p>Recurring Patronage / Subscriptions are generally tied to a creator’s channel, and the membership balance is charged on a monthly basis.</p>`
                        },
                        {
                            title: "Are donations publicly visible? Are there private donation options?",
                            content: `<p>Readers who wish to tip/donate may choose to keep their donation anonymous, in which case their username would not be shown to the creator. Otherwise, the default donation path will allow creators to see all the usernames of readers who donated to their Memo or subscribe to their channel.</p>
                            <p>From the public-facing view of a Memo or Channel, the number of donations or subscribers will be visible, but not the specific usernames nor the total amount.</p>`
                        },
                        {
                            title: "Is the amount of donations per content or creator visible to the public?",
                            content: `<p>The number of individuals that donated or subscribed is visible, but not the individual username listing nor the total amount.</p>`
                        }
                    ]
                }
            ]
        };
    },
    computed: {
        ...mapState({
            isMobileScreen: state => state.Application.isMobileScreen
        })
    },
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss" scoped>
.founders-faq {
    background-color: #181818;

    @media(max-width: $md) {
        padding-top: 98px;
    }

    figure {
        border-radius: 12px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;

        @media(max-width: $md) {
            border-radius: 0;
            justify-content: center;
            height: 225px;
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: 0;

            &::before {
                content: "";
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 1;
            }

            img {
                height: 100%;
            }
        }

        span {
            position: absolute;
            bottom: 30px;
            left: 100px;
            font-size: 144px;
            text-transform: uppercase;
            font-weight: 600;
            z-index: 2;

            @media(max-width: $md) {
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 34px;
            }
        }
    }

    /deep/ .vue-tabs {
        padding-top: 5px;

        &.stacked {
            .left-vertical-tabs {
                width: 40%;
                flex-shrink: 0;

                .nav-tabs-wrapper {
                    .nav-tabs {
                        display: block;
                        border: 0;
                        padding-right: 100px;

                        @media(max-width: $lg) {
                            padding-right: 0;
                        }

                        .tab {
                            position: relative;

                            a {
                                text-align: left;
                                color: rgba(235, 235, 245, 0.6);;
                                border-radius: 0;
                                border: 0;
                                font-size: 15px;
                                padding: 10px 15px;
                                padding-left: 0;
                                transition: all .2s;
                                height: 75px;
                                display: flex;
                                align-items: center;
                                font-weight: 400;

                                span {
                                    &.title {
                                        justify-content: start;
                                    }
                                }

                                &:hover {
                                    background-color: transparent;
                                }
                            }

                            &.active {
                                a {
                                    color: white;
                                    background-color: transparent;
                                }

                                &::before {
                                    content: "";
                                    position: absolute;
                                    width: 23px;
                                    height: 23px;
                                    background-image: url("~@assets/img/icons/faq.svg");
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                    left: -40px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }

                            &:focus {
                                outline: 0;
                            }
                        }
                    }
                }
            }

            .tab-content {
                &.right-text-tabs {
                    .tab-container {
                        padding-top: 10px;
                        min-height: 800px;

                        p {
                            font-size: 18px;
                            color: white;
                            line-height: 28px;
                            font-weight: 400;
                            margin-bottom: 40px;
                        }

                        span {
                            font-size: 18px;
                            color: white;
                            line-height: 38px;
                            font-weight: 400;
                        }

                        ul {
                            font-size: 18px;
                            color: white;
                            line-height: 38px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .faq-container {
        @media(max-width: $md) {
            z-index: 4;
            position: relative;
            margin-left: -15px;
            margin-right: -15px;
            margin-top: -55px;
        }

        .bulma-accordion {
            .card {
                border-radius: 0;
                margin-top: 0;

                /deep/ .card-header {
                    border-radius: 0 !important;
                    padding: 40px 20px;

                    .card-header-title {
                        h4 {
                            font-size: 19px;
                            line-height: 20px;
                            color: white;
                        }
                    }
                }

                /deep/ .accordion-body {
                    .card-content {
                        padding-bottom: 30px;

                        p, li, span {
                            color: white;
                            font-size: 17px;
                            line-height: 24px;
                            font-weight: 400;
                        }
                    }
                }

                &.card-active {
                    border-radius: 0 !important;

                    .card-header {
                        .card-header-title {
                            h4 {
                                font-weight: initial;
                                color: $primary-color;
                            }
                        }
                    }
                }
            }

            .card:nth-child(odd) {
                background-color: #3A3A3C;

                /deep/ .card-header {
                    background-color: #3A3A3C;
                }
            }

            .card:nth-child(even) {
                background-color: #2C2C2E;

                /deep/ .card-header {
                    background-color: #2C2C2E;
                }
            }
        }

        .faq-categories {
            .question-title {
                font-size: 22px;
                line-height: 22px;
                margin-bottom: 40px;
                margin-top: 20px;
            }

            /deep/ .nav-tabs-navigation {
                @media(max-width: $md) {
                    margin-bottom: 15px;
                }

                .nav-tabs-wrapper {
                    .nav-tabs {
                        border-bottom: 0;
                        flex-wrap: nowrap;
                        overflow-y: hidden;

                        .tab {
                            margin-left: 15px;
                            flex-shrink: 0;
                            margin-bottom: 0;

                            a {
                                border: 1px solid white;
                                border-radius: 30px;
                                font-size: 14px;
                                color: white;
                                padding: 7px 20px;
                                font-weight: 400;

                                &.active_tab,
                                &:hover  {
                                    background-color: $primary-color;
                                    border-color: $primary-color;
                                }
                            }
                        }

                        & .tab:first-child {
                            margin-left: 0;

                            @media(max-width: $md) {
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .vertical-vue-tab {
        margin-top: 60px;
    }
}
</style>
